import { useState } from "react";
import { Col, Form, Row, Button, Collapse } from "react-bootstrap"
import { getCountries } from "../helpers/CountryListHelper"
import { register } from "../services/UserService";

const initialFormData = Object.freeze({
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
  zipCode: "",
  country: "US",
  trialUpdate: false,
  newTrial: false
})
function RegisterForm() {
  const countryArr = getCountries();

  const [formData, setFormData] = useState(initialFormData);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [disabledSubmit, setDisabledsubmit] = useState(false);

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value.trim()
    });
  };
  const handleCheck = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.checked
    });
  };
  const handleSubmit = async event => {
    event.preventDefault();
    setDisabledsubmit(true);
    if(formData.password !== formData.confirmPassword) {
      setError("Please make sure both passwords match");
      setDisabledsubmit(false);
      return;
    }
    if(formData.password.length < 8){
      setError("Passwords much be at least 8 characters");
      setDisabledsubmit(false);
      return;
    }
    const registerRes = await register(formData);
    if (registerRes.accessToken) {
      await new Promise(r => setTimeout(r, 1600));
      setSuccess("Account creation successful. Redirecting you to the dashboard.")
      window.location.reload(false);
      return;
    } 
    else if(registerRes) {
      setError(registerRes);
      setDisabledsubmit(false);
    }
    else {
      setError("There was a problem creating your account. If this problem persists, please contact us");
      setDisabledsubmit(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row className="justify-content-center">
              <Col xs={10} sm={10} md={12} lg={12}>
          <Row className="pt-3 justify-content-center">
            <Form.Group as={Col} xs={12} md={5} className="pt-3">
              <Form.Label>First Name</Form.Label>
              <Form.Control onChange={handleChange} name="firstName"></Form.Control>
            </Form.Group>
            <Form.Group as={Col} xs={12} md={5} className="pt-3">
              <Form.Label>Last Name</Form.Label>
              <Form.Control onChange={handleChange} name="lastName"></Form.Control>
            </Form.Group>
          </Row>
          <Row className="justify-content-center">
            <Form.Group as={Col} xs={12} md={10} className="pt-3">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" onChange={handleChange} name="email"></Form.Control>
            </Form.Group>
          </Row>
          <Row className="justify-content-center">
            <Form.Group as={Col} xs={12} md={5} className="pt-3">
              <Form.Label >Password</Form.Label>
              <Form.Control type="password" onChange={handleChange} name="password"></Form.Control>
            </Form.Group>
            <Form.Group as={Col} xs={12} md={5} className="pt-3">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control type="password" onChange={handleChange} name="confirmPassword"></Form.Control>
            </Form.Group>
          </Row>
          <Row className="justify-content-center">
            <Form.Group as={Col} xs={12} md={3} className="pt-3">
              <Form.Label>Zipcode</Form.Label>
              <Form.Control onChange={handleChange} name="zipCode"></Form.Control>
            </Form.Group>
            <Form.Group as={Col} xs={12} md={7} className="pt-3">
              <Form.Label>Country</Form.Label>
              <Form.Select name="country" onChange={handleChange}>
                <option value="US">United States of America</option>
                {countryArr.map(({ code, name }) => (
                  <option key={name} value={code}>{name}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </Row>
          <Row className="justify-content-center pt-5">
            <Col xs={12} md={5}>
              <Form.Check
                label="Receive Trial Update Notifications"
                type="checkbox"
                name="isOptInUpdates"
                onChange={handleCheck}
              />
            </Col>
            <Col xs={12} md={5}>
              <Form.Check
                label="Recieve New Trial Notifications"
                type="checkbox"
                name="isOptInNew"
                onChange={handleCheck}
              />
            </Col>
          </Row>
          <Collapse in={error.length > 0 || success.length > 0}>
            <Row className="justify-content-center pt-3">
              <div className="text-center">
                <p className='h6 text-danger'>{error}</p>
                {success.length > 0 &&
                <p className="h6 text-success">{success}</p>}
              </div>
            </Row>
          </Collapse>
          <Row className="pt-5">
            <Col md={5}></Col>
            <Col>
              <Button variant="primary" type="submit" disabled={disabledSubmit}>
                Register
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  )
}

export default RegisterForm