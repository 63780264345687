import React, { useContext, useState } from 'react';
import { Form, Row, Col, Button, InputGroup, } from 'react-bootstrap'
import { TrialContextDispatch } from '../context/TrialContext';
import { getCountries, getCountryName, validateZip } from '../helpers/CountryListHelper';
import { handleResponse } from '../helpers/ResponseHelper';
import { userCache } from '../helpers/UserHelper';
import { search } from '../services/TrialService';
import SaveSearchModal from './SaveSearchModal';



function SearchForm() {

  const countryArr = getCountries();
  const user = JSON.parse(userCache());
  const [measure, setMeasure] = useState(user.country === ("US" || "UK") ? "mi" : "km");
  const userCountryName = getCountryName(user.country);
  const setTrials = useContext(TrialContextDispatch);
  const [error, setError] = useState();
  const initialFormData = Object.freeze({
    keyword: "",
    trialStatus: "ar",
    trialCreationDate: "0",
    zipCode: "",
    distance: 0,
    measure: measure,
    country: user.country
  });
  const [formData, setFormData] = useState(initialFormData);
  const [showModal, setShowModal] = useState(false);

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value.trim()
    });
  };
  const handleMeasure = (change) => {
    setMeasure(change)
    setFormData({
      ...formData,
      measure: change
    });
  }
  const handleSubmit = async event => {
    event.preventDefault();
    handleMeasure(measure);
    if (formData.country === "Global" && formData.zipCode !== "") {
      formData.zipCode = "";
    }
    if (formData.zipCode !== "" && !validateZip(formData.country, formData.zipCode)) {
      setError("Unable to validate postal code");
      return;
    }
    if (formData.distance === "" || formData.zipCode.length <= 0) {
      setFormData({
        ...formData,
        distance: 0
      });
    }
    setTrials(undefined);
    setError("");
    const results = await search(formData).then(handleResponse);
    if (results.length < 1 && formData.country !== "Global" && formData.distance > 1 && formData.zipCode !== "") {
      const newResults = await search({
        keyword: formData.keyword,
        trialStatus: formData.trialStatus,
        trialCreationDate: formData.trialCreationDate,
        zipCode: "",
        distance: 0,
        measure: measure,
        country: formData.country
      }).then(handleResponse);
      if(newResults.length < 1) {
        setTrials([{noResults:true}]);
      }
      else setTrials(newResults.map(obj => ({ ...obj, noResult: true })));
    }
    else if(results.length < 1) {
      setTrials([{noResults:true}]);
    } 
    else {
      setTrials(results.map(obj => ({ ...obj, isSearched: true })));
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit} noValidate>
        <Row className="mb-2">
          <Form.Group as={Col} xs={12} md={4} controlId="searchFormKeyword">
            <Form.Label>Keyword</Form.Label>
            <Form.Control placeholder="(Drug name, medical term, condition)" name='keyword' onChange={handleChange} />
          </Form.Group>

          <Form.Group as={Col} xs={12} md={4} controlId="searchFormTrialStatus">
            <Form.Label>Trial Status</Form.Label>
            <Form.Select defaultValue="Active Recruiting" name='trialStatus' onChange={handleChange}>
              <option value="ar">Active Recruiting</option>
              <option value="anr">Active Not Recruiting</option>
              <option value="nyr">Not Yet Recruiting</option>
            </Form.Select>
          </Form.Group>

          <Form.Group as={Col} xs={12} md={4} controlId="searchFormCreationDate">
            <Form.Label>Trial Creation Date</Form.Label>
            <Form.Select name='trialCreationDate' onChange={handleChange}>
              <option value="0">All trials</option>
              <option value="1">Less than 1 year ago</option>
              <option value="2">Less than 2 years ago</option>
              <option value="4">Less than 4 years ago</option>

            </Form.Select>
          </Form.Group>
        </Row>

    
        <Row className="mb-3">

        <Form.Group as={Col} xs={12} md={4} controlId="formGridZip">
            <Form.Label>Country</Form.Label>
            <Form.Select name='country' onChange={handleChange}>
              {<option value={user.country}>{userCountryName}</option>}
              {<option value="Global">Global</option>}
              {countryArr.map(({ code, name }) => (
                <option key={name} value={code}>{name}</option>
              ))}
            </Form.Select>
          </Form.Group>
          
          {formData.country === "US" &&
          <Form.Group as={Col} xs={6} md={4} controlId="searchFormZip" disabled>
            <Form.Label>Postal Code</Form.Label>
            <Form.Control
              name='zipCode'
              onChange={handleChange}
            />
          </Form.Group> }
          

          {formData.country === "US" &&
          <Form.Group as={Col} xs={6} md={4}>
            <Form.Label>Distance</Form.Label>
            <InputGroup>
              <Form.Control value={formData.distance} type='number' min={0} max={99999} step={20} name="distance" onChange={handleChange} />
              <Button variant={measure === "mi" ? "primary" : "outline-secondary"} onClick={() => handleMeasure("mi")}>mi</Button>
              <Button variant={measure === "km" ? "primary" : "outline-secondary"} onClick={() => handleMeasure("km")}>km</Button>
            </InputGroup>
          </Form.Group>}

          
        </Row>

        <Button variant="primary" type="submit">
          Search
        </Button>
        <div className='d-inline p-2'></div>
        <Button variant="info" onClick={() => setShowModal(!showModal)}>
          Save Search
        </Button>
        <span className='p-2 h6 text-danger'>{error}</span>
        <SaveSearchModal data={formData} show={showModal} onHide={() => setShowModal(false)} />

      </Form>
    </div>
  )
}

export default SearchForm